import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { authFetch } from '../authFetch';
import Notifications from '../components/Notifications';
import { displayError, displaySuccess } from '../components/utils';

const SubscriptionOptions = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [userSubscriptionLevel, setUserSubscriptionLevel] = useState(null);
    const [errorMessages, setErrorMessages] = useState('');
    const [successMessages, setSuccessMessages] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [processingTier, setProcessingTier] = useState(null);

    const showError = displayError(setErrorMessages);
    const showSuccess = displaySuccess(setSuccessMessages);

    // These are testing prices, make sure to replace them with live prices
    const subscriptionTiers = [
        {
            name: '(Early Access) Premium',
            price_id: 'price_1OfwE2L5xc0VRk2celX7te9m',
            price: '$7.50',
            description: 'Early adopter discount!',
            tokens: 'Get 100 tokens a month! (Limited time offer)',
            imageUrl: '/Assets/standardcoin.png',
            levelId: 2,
            features: [
                'Access to all premium features',
                'Early adopter discount for 1 year',
                'Save 62.5% off regular price',
                'Limited time offer until June 1st'
            ],
            color: 'bg-gradient-to-br from-primary/10 to-primary/5',
            borderColor: 'border-primary/30',
            recommended: true,
            badge: 'BEST VALUE'
        },
        {
            name: 'Premium',
            price_id: 'price_1OfwETL5xc0VRk2cdrHdMmP8',
            price: '$20.00',
            description: 'Standard premium plan',
            tokens: 'Get 100 tokens a month!',
            imageUrl: '/Assets/standardcoin.png',
            levelId: 3,
            features: [
                'Edit generated beats',
                'Generate full beats',
                'Purchase beats with full rights',
                'Upload stems to earn money',
                'Priority support'
            ],
            color: 'bg-base-100',
            borderColor: 'border-gray-200',
            recommended: false
        },
        {
            name: 'Enterprise',
            price_id: 'price_1OfwGYL5xc0VRk2cfcfSBNFZ',
            price: '$500.00',
            description: 'For businesses and labels',
            tokens: 'Get 1,000 tokens a month!',
            imageUrl: '/Assets/enterprise.png',
            levelId: 4,
            features: [
                'Instant support access',
                'Required for Record Labels & Publishers',
                'Early access to new features',
                'Ability to request features',
                'Copyright & publishing auditing'
            ],
            color: 'bg-gradient-to-br from-secondary/10 to-secondary/5',
            borderColor: 'border-secondary/30',
            recommended: false,
            badge: 'BUSINESS'
        }
    ];

    useEffect(() => {
        const isMounted = { current: true };
        
        const fetchSubscriptionLevel = async () => {
            try {
                // Skip if not authenticated or already loading
                if (!isAuthenticated || isLoading) return;
                
                // Check if we've already fetched subscription data in this session
                const hasFetchedSubscription = localStorage.getItem('hasFetchedSubscription');
                if (hasFetchedSubscription === 'true') return;
                
                const url = `${process.env.REACT_APP_BACKEND_URL}/subscription/level`;
                const data = await authFetch(url, {}, getAccessTokenSilently);
                
                if (isMounted.current) {
                    setUserSubscriptionLevel(data.level);
                    localStorage.setItem('hasFetchedSubscription', 'true');
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error fetching subscription level:", error);
                if (isMounted.current) {
                    setIsLoading(false);
                }
            }
        };

        if (isAuthenticated) {
            fetchSubscriptionLevel();
        } else {
            setIsLoading(false);
            // Clear the flag when user is not authenticated
            localStorage.removeItem('hasFetchedSubscription');
        }
        
        // Cleanup function
        return () => {
            isMounted.current = false;
        };
    }, [isAuthenticated]);  // Remove getAccessTokenSilently from dependencies

    const handleSubscriptionSelect = async (tier) => {
        console.log("Selected tier:", tier.levelId);
        console.log("Current user subscription level:", userSubscriptionLevel);

        if (!isAuthenticated) {
            showError('You must be logged in to subscribe.');
            return;
        }

        if (tier.levelId === userSubscriptionLevel) {
            console.log("User already subscribed to this plan");
            showError('You are already subscribed to this plan.');
            return;
        }

        try {
            setProcessingTier(tier.levelId);
            const idToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify({ price_id: tier.price_id })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const session = await response.json();
            window.location.href = session.checkoutUrl;
        } catch (error) {
            console.error('There was an issue:', error);
            showError('Failed to initiate subscription process');
            setProcessingTier(null);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-base-200 to-base-100 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                        Subscribe to YSM.AI
                    </h1>
                    <p className="text-lg md:text-xl max-w-3xl mx-auto text-base-content/80">
                        Choose a plan that fits your creative needs and start generating professional beats today
                    </p>
                </div>

                {/* Notifications */}
                <div className="max-w-3xl mx-auto mb-8">
                    <Notifications
                        errorMessages={errorMessages}
                        successMessages={successMessages}
                        setErrorMessages={setErrorMessages}
                        setSuccessMessages={setSuccessMessages}
                    />
                </div>

                {/* Loading State */}
                {isLoading ? (
                    <div className="flex justify-center items-center py-12">
                        <div className="loading loading-spinner loading-lg text-primary"></div>
                    </div>
                ) : (
                    /* Subscription Cards */
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {subscriptionTiers.map((tier, index) => (
                            <div 
                                key={index} 
                                className={`card ${tier.color} border-2 ${tier.borderColor} shadow-xl overflow-hidden transition-all duration-300 hover:scale-105 hover:shadow-2xl ${tier.recommended ? 'md:-mt-4 md:mb-4' : ''}`}
                            >
                                {tier.badge && (
                                    <div className="absolute top-0 right-0">
                                        <div className={`badge ${tier.recommended ? 'badge-primary' : 'badge-secondary'} m-2`}>
                                            {tier.badge}
                                        </div>
                                    </div>
                                )}
                                
                                <div className="card-body">
                                    <div className="flex items-center justify-between mb-4">
                                        <h2 className="card-title text-xl font-bold">{tier.name}</h2>
                                        <div className="avatar">
                                            <div className="w-12 h-12 rounded-full">
                                                <img src={tier.imageUrl} alt={tier.name} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="flex items-end mb-6">
                                        <span className="text-4xl font-extrabold">{tier.price}</span>
                                        <span className="text-base-content/70 ml-1 mb-1">USD / month</span>
                                    </div>
                                    
                                    <p className="text-sm mb-2 font-medium">{tier.description}</p>
                                    <p className="text-sm mb-4 text-primary font-medium">{tier.tokens}</p>
                                    
                                    <div className="divider my-1"></div>
                                    
                                    <ul className="space-y-3 mb-6">
                                        {tier.features.map((feature, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <svg className="h-5 w-5 text-success flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                </svg>
                                                <span className="text-sm">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                    <div className="card-actions mt-auto">
                                        <button 
                                            className={`btn w-full ${tier.levelId === userSubscriptionLevel 
                                                ? 'btn-disabled bg-success/20 text-success border-success/30' 
                                                : tier.recommended ? 'btn-primary' : 'btn-outline'}`}
                                            onClick={() => handleSubscriptionSelect(tier)}
                                            disabled={tier.levelId === userSubscriptionLevel || processingTier === tier.levelId}
                                        >
                                            {processingTier === tier.levelId ? (
                                                <>
                                                    <span className="loading loading-spinner loading-sm"></span>
                                                    Processing...
                                                </>
                                            ) : tier.levelId === userSubscriptionLevel ? (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                    </svg>
                                                    Current Plan
                                                </>
                                            ) : (
                                                'Subscribe'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Additional Information */}
                <div className="max-w-3xl mx-auto mt-16 text-center">
                    <div className="alert bg-base-100 shadow-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div>
                            <h3 className="font-bold">Secure Payments</h3>
                            <div className="text-sm">All payments are processed securely through Stripe. You can cancel your subscription at any time.</div>
                        </div>
                    </div>
                    
                    <button 
                        className="btn btn-ghost mt-8"
                        onClick={() => navigate('/subscription-features')}
                    >
                        View Plan Comparison
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionOptions;
