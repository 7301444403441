/**
 * Beat generation functions for the application
 */

import { displayError } from '../../components/utils';

/**
 * Handles generating a new beat
 * @param {string} beatTitle - The title/prompt for the beat
 * @param {Function} getAccessTokenSilently - Auth0 function to get access token
 * @param {boolean} isAuthenticated - Whether user is authenticated
 * @param {Function} handleLoginClick - Function to handle login click
 * @param {Function} setShowFullBeatButton - Function to set full beat button visibility
 * @param {Function} setAudioWaveform - Function to set audio waveform
 * @param {Function} setLoading - Function to set loading state
 * @param {Function} setStepMessage - Function to set step message
 * @param {Array} generationMessages - Array of messages to cycle through during generation
 * @param {Function} setTokensRemaining - Function to set tokens remaining
 * @param {Function} setProducerInfo - Function to set producer info
 * @param {Function} setBeatKey - Function to set beat key
 * @param {Function} setSearchQuery - Function to set search query
 * @param {Function} setBeatStructureID - Function to set beat structure ID
 * @param {Function} setBeatMode - Function to set beat mode
 * @param {Function} setBeatBPM - Function to set beat BPM
 * @param {Function} setBeatId - Function to set beat ID
 * @param {Function} fetchBeatStructure - Function to fetch beat structure
 * @returns {Promise<void>}
 */
export const handleGenerateBeat = async (
    beatTitle,
    getAccessTokenSilently,
    isAuthenticated,
    handleLoginClick,
    setShowFullBeatButton,
    setAudioWaveform,
    setLoading,
    setStepMessage,
    generationMessages,
    setTokensRemaining,
    setProducerInfo,
    setBeatKey,
    setSearchQuery,
    setBeatStructureID,
    setBeatMode,
    setBeatBPM,
    setBeatId,
    fetchBeatStructure
) => {
    let messageInterval = null;
    let currentIndex = 0;
    
    try {
        if (!isAuthenticated) {
            alert("You must be logged in to generate a beat.");
            handleLoginClick();
            return;
        }
        
        setShowFullBeatButton(false);
        setAudioWaveform(null);
        setLoading(true);
        setStepMessage(generationMessages[0]);

        // Set up message cycling
        messageInterval = setInterval(() => {
            currentIndex = (currentIndex + 1) % generationMessages.length;
            setStepMessage(generationMessages[currentIndex]);
        }, 3000);

        // Import and use the generateBeat function from sendData.js
        const { generateBeat } = await import('../../API/backend/sendData');
        const responseData = await generateBeat(beatTitle, getAccessTokenSilently);
        
        console.log('Beat generation response:', responseData);
        
        if (responseData.error) {
            throw new Error(responseData.error);
        }

        if (responseData.audio_url) {
            console.log('Setting audio URL:', responseData.audio_url);
            
            // Pre-fetch the audio to ensure it's accessible
            try {
                const audioResponse = await fetch(responseData.audio_url, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                });
                
                if (!audioResponse.ok) {
                    throw new Error('Failed to fetch audio file');
                }
                
                // Only set the audio URL if we successfully fetched it
                setAudioWaveform(responseData.audio_url);
                setProducerInfo(responseData.producer_info || []);
                
                // Update tokens directly from the response instead of making another API call
                if (responseData.tokens_remaining !== undefined) {
                    setTokensRemaining(responseData.tokens_remaining);
                }
                
                setBeatKey(responseData.Key);
                setSearchQuery(responseData.search_query);
                setBeatStructureID(responseData.beat_structuring);
                setBeatMode(responseData.Mode);
                setBeatBPM(responseData.BPM);
                setBeatId(responseData.beat_id);
                
                setShowFullBeatButton(true);
                await fetchBeatStructure();
                
                // Only fetch token count if not provided in the response
                if (responseData.tokens_remaining === undefined) {
                    const { fetchNewTokenCount } = await import('../../API/backend/sendData');
                    await fetchNewTokenCount(getAccessTokenSilently, setTokensRemaining);
                }
            } catch (audioError) {
                console.error('Error fetching audio:', audioError);
                throw new Error('Failed to load audio file. Please try again.');
            }
        } else {
            throw new Error('No audio URL received from server');
        }

    } catch (error) {
        console.error("Error during beat generation:", error);
        handleGenerationError(error);
    } finally {
        setLoading(false);
        clearInterval(messageInterval);
        setStepMessage('');
    }
};

/**
 * Regenerates a beat from past generations
 * @param {string} beatId - The ID of the beat to regenerate
 * @param {Function} getAccessTokenSilently - Auth0 function to get access token
 * @param {Function} setLoading - Function to set loading state
 * @param {Function} setAudioWaveform - Function to set audio waveform
 * @param {Function} setProducerInfo - Function to set producer info
 * @param {Function} setBeatKey - Function to set beat key
 * @param {Function} setBeatMode - Function to set beat mode
 * @param {Function} setSearchQuery - Function to set search query
 * @param {Function} setBeatBPM - Function to set beat BPM
 * @param {Function} setBeatId - Function to set beat ID
 * @param {Function} setShowFullBeatButton - Function to set full beat button visibility
 * @returns {Promise<void>}
 */
export const handleRegenerateBeat = async (
    beatId,
    getAccessTokenSilently,
    setLoading,
    setAudioWaveform,
    setProducerInfo,
    setBeatKey,
    setBeatMode,
    setSearchQuery,
    setBeatBPM,
    setBeatId,
    setShowFullBeatButton
) => {
    setLoading(true);
    try {
        // Import and use the regenerateBeat function from sendData.js
        const { regenerateBeat } = await import('../../API/backend/sendData');
        const data = await regenerateBeat(beatId, getAccessTokenSilently);
        
        setAudioWaveform(data.audio_url);
        setProducerInfo(data.producer_info || []);
        setBeatKey(data.Key);
        setBeatMode(data.Mode);
        setSearchQuery(data.search_query);
        setBeatBPM(data.BPM);
        setBeatId(data.beat_id);
        setShowFullBeatButton(true); // Show the button for further actions
    } catch (error) {
        console.error('Error regenerating beat:', error);
        displayError("Failed to regenerate beat. Please try again.");
    } finally {
        setLoading(false);
    }
};

/**
 * Handles generating a full beat
 * @param {Function} getAccessTokenSilently - Auth0 function to get access token
 * @param {Function} setLoading - Function to set loading state
 * @param {Function} setAudioWaveform - Function to set audio waveform
 * @param {Function} setProducerInfo - Function to set producer info
 * @param {Function} setBeatStructure - Function to set beat structure
 * @param {Function} setBeatStructureID - Function to set beat structure ID
 * @param {Function} setShowFullBeatButton - Function to set full beat button visibility
 * @returns {Promise<void>}
 */
export const handleMakeFullBeat = async (
    getAccessTokenSilently,
    setLoading,
    setAudioWaveform,
    setProducerInfo,
    setBeatStructure,
    setBeatStructureID,
    setShowFullBeatButton
) => {
    setLoading(true);
    try {
        // Import and use the generateFullBeat function from sendData.js
        const { generateFullBeat } = await import('../../API/backend/sendData');
        const data = await generateFullBeat(getAccessTokenSilently);
        
        console.log('Full beat response data:', data);

        setAudioWaveform(data.audio_url);
        setProducerInfo(data.producer_info || []);

        if (data.beat_structuring) {
            const structureArray = data.beat_structuring.split('');
            setBeatStructure(structureArray);
            setBeatStructureID(structureArray);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            displayError("Request timed out after 5 minutes. Please try again.");
        } else {
            displayError(error.message || "An error occurred during full beat generation.");
        }
        console.error('Full beat generation error:', error);
    } finally {
        setLoading(false);
        setShowFullBeatButton(true);
    }
};

/**
 * Handles errors during beat generation
 * @param {Error} error - The error that occurred
 */
export const handleGenerationError = (error) => {
    if (error.name === 'AbortError') {
        displayError("Generation timed out. Please try again.");
    } else if (error.message === 'Client disconnected') {
        displayError("Connection was interrupted. Please try again.");
    } else if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
        displayError("Network error. Please check your connection and try again.");
    } else if (error.message.includes('Insufficient credits')) {
        displayError(error.message);
    } else if (error.message.includes('Progress updates stalled')) {
        displayError("Progress updates stalled. Please try again.");
    } else {
        displayError("An unexpected error occurred. Please try again.");
    }
    
    // Optionally reload after serious errors
    if (error.name === 'AbortError' || error.message === 'Client disconnected') {
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }
};
