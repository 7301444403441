/**
 * Session management functions for authentication
 */

/**
 * Handles user login via Auth0
 * @param {Function} loginWithRedirect - Auth0 function to redirect to login page
 */
export const handleLogin = (loginWithRedirect) => {
    loginWithRedirect();
};

/**
 * Handles user logout, clearing local storage and calling Auth0 logout
 * @param {Function} logout - Auth0 logout function
 * @param {Function} resetUserState - Function to reset user-related state
 * @returns {Promise<void>}
 */
export const handleLogout = async (logout, resetUserState) => {
    // URL of backend logout endpoint
    const backendLogoutUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/logout`;

    try {
        // Asynchronously call the backend logout endpoint
        const response = await fetch(backendLogoutUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', 
        });

        if (!response.ok) {
            throw new Error('Failed to log out from backend.');
        }
    } catch (error) {
        console.error('Error during backend logout:', error);
    }

    // Clear local storage or session storage
    localStorage.removeItem('userToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('userDataSent');
    localStorage.removeItem('hasRefreshedPostLogin');
    sessionStorage.removeItem('userSession');
    
    // Reset user state in the app
    resetUserState();

    // Logout from Auth0
    logout({ returnTo: window.location.origin });
};

/**
 * Handles page refresh after login
 * @param {Function} setShowLoadingOverlay - Function to set loading overlay state
 * @returns {Function} Cleanup function to clear timeout
 */
export const handleLoginRefresh = (setShowLoadingOverlay) => {
    // Only run this once when the component mounts
    if (window.location.search.includes("code=") && !localStorage.getItem('hasRefreshedPostLogin')) {
        setShowLoadingOverlay(true);  
        localStorage.setItem('hasRefreshedPostLogin', 'true');
      
        // Use a timeout to avoid multiple refreshes
        const timeoutId = setTimeout(() => {
            window.location.reload();
        }, 3000); // 3 seconds
        
        // Return cleanup function to clear timeout if component unmounts
        return () => clearTimeout(timeoutId);
    } else if (localStorage.getItem('hasRefreshedPostLogin') && !window.location.search.includes("code=")) {
        // This means we've already refreshed after login, so we can clear the loading overlay
        setShowLoadingOverlay(false);
        // Clear the flag after successful login flow completion
        localStorage.removeItem('hasRefreshedPostLogin');
    }
    
    // Return empty cleanup function if conditions aren't met
    return () => {};
};

/**
 * Sets up a safety timeout to clear loading state if it gets stuck
 * @param {boolean} showLoadingOverlay - Current loading overlay state
 * @param {Function} setShowLoadingOverlay - Function to set loading overlay state
 * @returns {Function} Cleanup function to clear timeout
 */
export const setupLoadingSafetyTimeout = (showLoadingOverlay, setShowLoadingOverlay) => {
    if (showLoadingOverlay) {
        const safetyTimeout = setTimeout(() => {
            setShowLoadingOverlay(false);
            localStorage.removeItem('hasRefreshedPostLogin');
        }, 10000); // 10 seconds max loading time
        
        return () => clearTimeout(safetyTimeout);
    }
    return () => {};
};

/**
 * Resets UI elements when user is not authenticated
 * @param {boolean} isAuthenticated - Whether user is authenticated
 * @param {Function} setShowFullBeatButton - Function to set full beat button visibility
 * @param {Function} setShowBeatEditor - Function to set beat editor visibility
 * @param {Function} setAudioWaveform - Function to set audio waveform
 * @param {Function} setBeatTitle - Function to set beat title
 */
export const handleUiReset = (
    isAuthenticated,
    setShowFullBeatButton,
    setShowBeatEditor,
    setAudioWaveform,
    setBeatTitle
) => {
    if (!isAuthenticated) {
        // Reset waveform player and remove current beat
        setShowFullBeatButton(false);
        setShowBeatEditor(false);
        setAudioWaveform(null);
        setBeatTitle('');
    }
};

/**
 * Handles cleanup when user logs out
 * @param {boolean} isAuthenticated - Whether user is authenticated
 * @param {boolean} isLoading - Whether authentication is loading
 * @param {Function} setTokensRemaining - Function to set tokens remaining
 * @param {Function} setUserSubscriptionLevel - Function to set user subscription level
 */
export const handleLogoutCleanup = (
    isAuthenticated,
    isLoading,
    setTokensRemaining,
    setUserSubscriptionLevel
) => {
    if (!isAuthenticated && !isLoading) {
        // User has logged out, clear user data here
        setTokensRemaining(null);
        setUserSubscriptionLevel(null);
        localStorage.removeItem('userDataSent'); // Clear the flag when user logs out
        localStorage.removeItem('hasRefreshedPostLogin');
    }
};

/**
 * Fetches user data after authentication
 * @param {boolean} isAuthenticated - Whether user is authenticated
 * @param {boolean} isLoading - Whether authentication is loading
 * @param {Object} user - User object from Auth0
 * @param {Function} getIdTokenClaims - Auth0 function to get ID token claims
 * @param {Function} getAccessTokenSilently - Auth0 function to get access token
 * @param {Function} logout - Auth0 logout function
 * @param {Function} handleLoginClick - Function to handle login click
 * @param {Function} setUserSubscriptionLevel - Function to set user subscription level
 * @param {Function} setTokensRemaining - Function to set tokens remaining
 * @param {number|null} userSubscriptionLevel - Current user subscription level
 * @param {number|null} tokensRemaining - Current tokens remaining
 * @returns {Function} Cleanup function
 */
export const fetchUserData = (
    isAuthenticated,
    isLoading,
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
    handleLoginClick,
    setUserSubscriptionLevel,
    setTokensRemaining,
    userSubscriptionLevel,
    tokensRemaining
) => {
    // Skip if not authenticated or if we're still loading
    if (!isAuthenticated || isLoading) return () => {};
    
    // Use a ref to track if the effect is already running
    let isMounted = true;
    
    const fetchUserRelatedData = async () => {
        if (!isMounted) return;
        
        try {
            // Only send user data to backend once per session
            if (!localStorage.getItem('userDataSent') && user) {
                const userProfile = {
                    email: user.email,
                    username: user.username || user.nickname,
                };

                // Import and call sendUserDataToBackend
                const { sendUserDataToBackend } = await import('./sendData');
                await sendUserDataToBackend(userProfile, getIdTokenClaims, logout, handleLoginClick);
                localStorage.setItem('userDataSent', 'true');
            }
            
            // Fetch subscription level and tokens only if not already loaded
            if (userSubscriptionLevel === null || tokensRemaining === null) {
                // Import functions from sendData
                const { getSubscriptionLevel, fetchNewTokenCount } = await import('./sendData');
                
                const [subscriptionLevel, tokensData] = await Promise.all([
                    getSubscriptionLevel(getAccessTokenSilently),
                    fetchNewTokenCount(getAccessTokenSilently, setTokensRemaining)
                ]);

                if (subscriptionLevel) {
                    setUserSubscriptionLevel(subscriptionLevel);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    fetchUserRelatedData();
    
    // Cleanup function
    return () => {
        isMounted = false;
    };
};
