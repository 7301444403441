import { useState, useEffect, useMemo } from 'react';

/**
 * Custom hook to manage rotating placeholder text
 * @returns {Object} An object containing the current placeholder text
 */
export const usePlaceholderText = () => {
    const [placeholder, setPlaceholder] = useState('');

    // Define the placeholder texts
    const placeholders = useMemo(() => [
        'What type of beat do you want to generate?',
        'Enter an artist name or genre you\'d like to make',
        'Try trap type beat with country drums',
        'Best results come from \'Artist Name\' Type Beat'
    ], []);

    // Cycle through the placeholders
    useEffect(() => {
        const delay = 100; // delay in milliseconds
        const intervalDuration = 5000; // same as CSS animation
    
        // Set initial placeholder
        setPlaceholder(placeholders[0]);
        
        const timeout = setTimeout(() => {
            let index = 0;
            const interval = setInterval(() => {
                index = (index + 1) % placeholders.length;
                setPlaceholder(placeholders[index]);
            }, intervalDuration);
    
            return () => clearInterval(interval);
        }, delay);
    
        return () => clearTimeout(timeout);
    }, [placeholders]);

    return { placeholder };
};

export default usePlaceholderText;
