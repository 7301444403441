import React, { useState, useEffect} from 'react';
import './App.css';
import './tailwind.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// Music Components
import WaveformPlayer from './components/WaveformPlayer';
import BeatSectionEditor from './components/BeatSectionEditor'; // Adjust the path as necessary
// About & Legal Pages
import AboutOurTeam from './Pages/About'; // Adjust the path as necessary
import SubscriptionFeatures from './Pages/SubscriptionFeatures'; // Adjust the path as necessary
import RightsAndUsage from './Pages/RightsAndUsage'; // Adjust the path as necessary
import TermsConditions from './Pages/TermsConditions'; // Adjust the path as necessary
import Banner from './signups/Banner'; // Adjust the path as necessary
//Signup & Set Up Pages
import ProducerForm from './signups/Producer-Signup';
import StripeConnectSuccess from './signups/stripe-connect-success';
import ContributorSetup from './signups/contributor-setup';
import LandingPage from './Pages/landingPage';
import AccountPage from'./Pages/AccountPage';
import SubscriptionOptions from './Pages/SubscriptionOptions'; // Adjust the path as necessary
import SubscriptionSuccess from './Pages/SubscriptionSuccess';
//Various Pages
import DataSet from './Pages/dataSet';
import Licenses from './Pages/licenses';
import AdminPortal from './Pages/AdminPortal';
// Alerts & Notifications
import Notifications from './components/Notifications';
import { displayError, displaySuccess } from './components/utils';
import LearnMoreAccordion from './components/LearnMoreAccordion';
import Layout from './components/ui/Layout';
import RequireAuth from './components/RequireAuth';
// Contributor Content
import SamplesUpload from './Pages/uploadSamples';
import DrumUpload from './Pages/uploadDrums';
// Session Management
import {
    handleLogin,
    handleLogout as sessionHandleLogout,
    handleLoginRefresh,
    setupLoadingSafetyTimeout,
    handleUiReset,
    handleLogoutCleanup,
    fetchUserData
} from './API/backend/session';
// Beat Generation
import {
    handleGenerateBeat as genBeatsHandleGenerateBeat,
    handleRegenerateBeat as genBeatsHandleRegenerateBeat,
    handleMakeFullBeat as genBeatsHandleMakeFullBeat,
    handleGenerationError
} from './components/beatGen/genBeats';
// UI Components
import usePlaceholderText from './components/ui/placeHolder';

function App() {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        isLoading,
        getAccessTokenSilently,
        getIdTokenClaims,
        user
    } = useAuth0();

    // Get placeholder text from custom hook
    const { placeholder } = usePlaceholderText();

    const [tokensRemaining, setTokensRemaining] = useState(null);
    const [userSubscriptionLevel, setUserSubscriptionLevel] = useState(null);
    const [beatTitle, setBeatTitle] = useState('');
    const [audioWaveform, setAudioWaveform] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [producerInfo, setProducerInfo] = useState([]);
    const [beatKey, setBeatKey] = useState('');
    const [beatMode, setBeatMode] = useState('');
    const [beatBPM, setBeatBPM] = useState(0);
    const [beatId, setBeatId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stepMessage, setStepMessage] = useState('');
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false); // New state for loading overlay  
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const [beatStructureID, setBeatStructureID] = useState(''); // State to show beat structure IDs

    const [errorMessages, setErrorMessages] = useState(''); // Initialize error message state
    const [successMessages, setSuccessMessages] = useState(''); // Initialize success message state

    const [isTyping, setIsTyping] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState('');    

    const [showFullBeatButton, setShowFullBeatButton] = useState(false); //Button For Make Full Beat
    const [showBeatEditor, setShowBeatEditor] = useState(false); // State to control the visibility of beat editor
    const [beatStructure, setBeatStructure] = useState([]); // State to store the beat structure
    const [showEditDropdown, setShowEditDropdown] = useState(false); // State to control dropdown visibility

    // New state for cycling messages
    const [generationMessages] = useState([
        "Getting audio files...",
        "Arranging drums...",
        "Arranging melody files...",
        "Generating structure...",
        "Applying mixing..."
    ]);
    
    const [editMode, setEditMode] = useState('swap'); // 'swap', 'remove', or 'global'

    // Function to handle edit mode change
    const handleEditModeChange = (mode) => {
        setEditMode(mode);
        setShowBeatEditor(!showBeatEditor); // Toggle visibility based on current state
        setShowBeatEditor(true); // Ensure the beat editor is shown
        setShowEditDropdown(false); // Hide the dropdown menu
    };
    
    let errorTimeout = null; // Declare a variable to store the timeout

    const toggleDropDown = () => {
        setShowEditDropdown(prevShowEditDropdown => !prevShowEditDropdown);
    };

    const clearError = () => {
        // Get the error message element
        const errorMessageElement = document.querySelector('.errmsg');
        if (errorMessageElement) {
            errorMessageElement.classList.add('errmsg-fadeout'); // Add fade-out class
        }
        // Clear the error message from state after the animation duration
        setTimeout(() => setSubscriptionError(''), 2000); // 2 seconds for fade-out
    };

    //Set the loading state so it can be used across the app
    const setLoadingState = (isLoading) => {
        setLoading(isLoading);
    };
    

    // Function to close the beat editor
    const closeBeatEditor = () => {
        // console.log("Closing Beat Editor"); // Debugging log
        setShowBeatEditor(false);
        setEditMode(null); // Reset edit mode when closing the editor
    };
    
    useEffect(() => {
        return () => {
            // Cleanup function to clear timeout if component unmounts
            clearTimeout(errorTimeout);
        };
    }, [errorTimeout]);
    
    const handleSetSubscriptionError = (message) => {
        setSubscriptionError(message);
        errorTimeout = setTimeout(clearError, 4000); // Clear the error after 6 seconds
    };

    //Update the beat file via change stems
    const handleAudioUrlUpdate = (newAudioUrl) => {
        setAudioWaveform(newAudioUrl);
    };
    
    //Login/Register Modal
    const handleLoginClick = () => {
        handleLogin(loginWithRedirect);
    };

//CHange the beat stems by calling the URL update and the beat stems selections
const handleEditBeat = (selectedDrums, newAudioUrl) => {
    handleApplyChanges(selectedDrums);
    handleAudioUrlUpdate(newAudioUrl);
    closeBeatEditor();
};

// Reset UI when user logs out
useEffect(() => {
    handleUiReset(
        isAuthenticated,
        setShowFullBeatButton,
        setShowBeatEditor,
        setAudioWaveform,
        setBeatTitle
    );
}, [isAuthenticated]);

// Handle login refresh
useEffect(() => {
    return handleLoginRefresh(setShowLoadingOverlay);
}, []); // Empty dependency array ensures this only runs once

// Fetch user data after authentication
useEffect(() => {
    return fetchUserData(
        isAuthenticated,
        isLoading,
        user,
        getIdTokenClaims,
        getAccessTokenSilently,
        logout,
        handleLoginClick,
        setUserSubscriptionLevel,
        setTokensRemaining,
        userSubscriptionLevel,
        tokensRemaining
    );
}, [isAuthenticated, isLoading, user, getIdTokenClaims, getAccessTokenSilently, logout, userSubscriptionLevel, tokensRemaining]);

// Safety timeout for loading state
useEffect(() => {
    return setupLoadingSafetyTimeout(showLoadingOverlay, setShowLoadingOverlay);
}, [showLoadingOverlay]);

// Handle logout cleanup
useEffect(() => {
    handleLogoutCleanup(
        isAuthenticated,
        isLoading,
        setTokensRemaining,
        setUserSubscriptionLevel
    );
}, [isAuthenticated, isLoading]);

// Function to open the beat editor
const openBeatEditor = () => {
    if (audioWaveform && beatStructure && beatStructure.length > 0) {
        setShowBeatEditor(true);
    } else {
        console.error("Beat structure not loaded yet or no beat generated");
    }
};

// Function to fetch the beat structure
const fetchBeatStructure = async (isFullBeat = false) => {
    // Skip if we already have a beat structure and it's not a full beat request
    if (beatStructure.length > 0 && !isFullBeat) {
        return;
    }
    
    try {
        const idToken = await getAccessTokenSilently();
        const endpoint = isFullBeat ? '/get_full_beat_structure' : '/get_beat_structure';
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Failed to fetch beat structure.');
        }
        const data = await response.json();

        // Split the structure string into an array
        const structureArray = data.structure.split('');

        setBeatStructure(structureArray); // Set beat structure as an array
    } catch (error) {
        console.error('Error fetching beat structure:', error);
    }
};



useEffect(() => {
    if (isAuthenticated) {
        fetchBeatStructure();
    }
}, [isAuthenticated, getAccessTokenSilently]);

// Function to handle the button click for applying changes for beat editing
const handleApplyChanges = (selectedDrums, selectedSection) => {
    setLoadingState(true);
};

// Function to handle the button click for generating beats
const handleGenerateBeat = async () => {
    await genBeatsHandleGenerateBeat(
        beatTitle,
        getAccessTokenSilently,
        isAuthenticated,
        handleLoginClick,
        setShowFullBeatButton,
        setAudioWaveform,
        setLoading,
        setStepMessage,
        generationMessages,
        setTokensRemaining,
        setProducerInfo,
        setBeatKey,
        setSearchQuery,
        setBeatStructureID,
        setBeatMode,
        setBeatBPM,
        setBeatId,
        fetchBeatStructure
    );
};

//Regenerate beat from past generations
const handleRegenerateBeat = async (beatId) => {
    await genBeatsHandleRegenerateBeat(
        beatId,
        getAccessTokenSilently,
        setLoading,
        setAudioWaveform,
        setProducerInfo,
        setBeatKey,
        setBeatMode,
        setSearchQuery,
        setBeatBPM,
        setBeatId,
        setShowFullBeatButton
    );
};

// Function to handle the button click for making a full beat
const handleMakeFullBeat = async () => {
    await genBeatsHandleMakeFullBeat(
        getAccessTokenSilently,
        setLoading,
        setAudioWaveform,
        setProducerInfo,
        setBeatStructure,
        setBeatStructureID,
        setShowFullBeatButton
    );
};

// Function to handle logout
const handleAppLogout = () => {
    // Create a function to reset user state
    const resetUserState = () => {
        setTokensRemaining(null);
        setUserSubscriptionLevel(null);
    };
    
    // Call the session logout function
    sessionHandleLogout(logout, resetUserState);
};

if (isLoading || showLoadingOverlay){

        return (
            <div className="loading-overlay">
                <div className="loading-spinner"></div>
                 Please wait. Page is Loading...
            </div>
        );

}



    return (
        
        <Router>
        <div className="App">
            <Notifications
            errorMessages={errorMessages}
            successMessages={successMessages}
            setErrorMessages={setErrorMessages}
            setSuccessMessages={setSuccessMessages}
            displayError={displayError}
            displaySuccess={displaySuccess}
        />
            {/* <AlertComponent /> Add the alert component */}



                    {subscriptionError && <div className="errmsg">{subscriptionError}</div>}
                     <Routes>

                     <Route element={<Layout
                                isAuthenticated={isAuthenticated}
                                tokensRemaining={tokensRemaining}
                                showDropdown={showDropdown}
                                setShowDropdown={setShowDropdown}
                                handleLogout={handleAppLogout}
                                handleLoginClick={handleLoginClick}
                                />}>
                                
                        <Route path="/" element={
                            <RequireAuth>
                            <>
                                {errorMessages && <div className="errmsg">{errorMessages}</div>}
                                {!isAuthenticated && <div className="errmsg">Please log in to use the beat generator.</div>}
                                <MainContent 
                                    placeholder={placeholder}
                                    handleGenerateBeat={handleGenerateBeat}
                                    audioWaveform={audioWaveform}
                                    setBeatTitle={setBeatTitle}
                                    isTyping={isTyping}
                                    setIsTyping={setIsTyping}
                                    loading={loading}
                                    searchQuery={searchQuery}
                                    beatStructureID={beatStructureID}
                                    producerInfo={producerInfo}
                                    beatKey={beatKey}
                                    beatMode={beatMode}
                                    beatBPM={beatBPM}
                                    beatId={beatId}
                                    showFullBeatButton={showFullBeatButton}
                                    handleMakeFullBeat={handleMakeFullBeat}
                                    openBeatEditor={openBeatEditor}
                                    handleEditBeat={handleEditBeat}
                                    showBeatEditor={showBeatEditor}
                                    setShowBeatEditor={setShowBeatEditor}
                                    beatStructure={beatStructure}
                                    handleEditModeChange={handleEditModeChange}
                                    toggleDropDown={toggleDropDown}
                                    showEditDropdown={showEditDropdown}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                    setLoadingState={setLoadingState}
                                    closeBeatEditor={closeBeatEditor}
                                    setShowEditDropdown={setShowEditDropdown}
                                    userSubscriptionLevel={userSubscriptionLevel}
                                    stepMessage={stepMessage}
                                />
                            </>

                            </RequireAuth>
                        } />
                        <Route path="/subscriptions" element={<SubscriptionOptions setSubscriptionError={handleSetSubscriptionError} />} />
                        <Route path="/account/profile" element={<RequireAuth><AccountPage handleRegenerateBeat={handleRegenerateBeat} /> </RequireAuth>} />
                        <Route path="/ysm-licenses" element={<Licenses />} />
                        <Route path="/about" element={<AboutOurTeam />} />
                        <Route path="/subscription-features" element={<SubscriptionFeatures />} />
                        <Route path="/rights-usage" element={<RightsAndUsage />} />
                        <Route path="/terms-conditions" element={<TermsConditions />} />
                        <Route path="/choice" element={<Banner />} />
                        <Route path="/Producer-Signup" element={<RequireAuth> <ProducerForm /></RequireAuth>} />
                        <Route path="/stripe-connect/success" element={<StripeConnectSuccess />} />
                        <Route path="/contributor-setup" element={<RequireAuth><ContributorSetup /></RequireAuth>} />
                        <Route path="/data-set" element={<DataSet />} />
                        <Route path="/upload-samples" element={<RequireAuth> <SamplesUpload /></RequireAuth>} />
                        <Route path="/upload-drums" element={<RequireAuth> <DrumUpload /></RequireAuth>} />
                        <Route path="/success" element={<SubscriptionSuccess />} />
                        <Route path="/admin-portal" element={<RequireAuth> <AdminPortal /></RequireAuth> } />

                   </Route>
                         <Route path='/welcome' element={<LandingPage />} />
                    </Routes>

            </div>
            <LearnMoreAccordion /> 

            </Router>
        
        );
                    }

                    
function MainContent({ 
    placeholder, 
    handleGenerateBeat, 
    audioWaveform, 
    setBeatTitle, 
    isTyping, 
    setIsTyping, 
    loading, 
    producerInfo, 
    showFullBeatButton, 
    handleMakeFullBeat,
    showBeatEditor, 
    beatStructure, 
    handleEditBeat, 
    editMode, 
    handleEditModeChange, 
    toggleDropDown, 
    showEditDropdown,  
    setShowEditDropdown, 
    setLoadingState, 
    closeBeatEditor, 
    beatBPM, 
    beatMode, 
    beatKey, 
    userSubscriptionLevel, 
    searchQuery, 
    beatStructureID, 
    beatId,
    stepMessage
}) 
{ 
    return (
        <main>
            <div className="search-container">
                <div className="placeholder-container"> 
                {!isTyping && (
                     <div className="placeholder-text" style={{ animationName: 'slideCube' }}>
                     {placeholder} 
                 </div>
                    )}
                </div>
                <textarea
                    className="search-input"
                    rows="1" // Initial number of rows
                    onChange={e => {
                        setBeatTitle(e.target.value);
                        if (e.target.value) {
                        setIsTyping(true);
                        } else {
                        setIsTyping(false);
                        }
                        // Adjust the textarea height based on content
                        e.target.style.height = 'auto'; // Reset height
                        e.target.style.height = e.target.scrollHeight + 'px'; // Set to scrollHeight
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && !loading) {
                        e.preventDefault(); // Prevent default behavior of Enter key
                        handleGenerateBeat();
                        }
                    }}
                    />
                <div className='button'>
                    <button className="btn bg-sky-400 hover:bg-blue-600" onClick={handleGenerateBeat} disabled={loading}>Generate</button>
                </div>
                
                </div>
                {loading && (
                    <div className="w-full max-w-3xl mx-auto mt-8 px-4">
                        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20">
                            {/* Modern Loading Animation */}
                            <div className="flex items-center justify-center mb-6">
                                <div className="relative">
                                    <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div>
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <svg className="w-8 h-8 text-blue-500" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6l4 2"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            {/* Status Text */}
                            <div className="text-center mb-8">
                                <h3 className="text-xl font-semibold text-white mb-2">
                                    Generating Your Beat
                                </h3>
                                <p className="text-blue-200/80">
                                    Please wait while we craft your unique sound...
                                </p>
                            </div>

                            {/* Message Display */}
                            <div className="mt-4 p-4 rounded-lg bg-blue-900/20 border border-blue-500/20">
                                <div className="flex items-center space-x-2">
                                    <svg className="w-5 h-5 text-blue-400 animate-pulse" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M13 10V3L4 14h7v7l9-11h-7z" />
                                    </svg>
                                    <p className="text-sm text-blue-200/90 font-medium animate-fade-in">
                                        {stepMessage}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                        
        {!loading && audioWaveform && (
            <div className="Audio-Player">
                <WaveformPlayer 
                beat_id={beatId}
                audio_url={audioWaveform}
                //New Feature Search Query Feb 28, 2024
                beatTitle={searchQuery}
                beatStructureLabels={beatStructureID}
                //////////////
                Beatkey={beatKey}
                mode={beatMode}
                bpm={beatBPM} />
                <div className="producer-info">
                <div><strong>Contributed Producers:</strong></div>
                <br/>
                    {producerInfo.map((info, index) => (
                        <div key={index}>
                            <strong>{info.producer}</strong>: {info.percentage}
                        </div>
                    ))}
                </div>
            </div>
        )}
        {audioWaveform && showFullBeatButton && (
            <button 
                onClick={handleMakeFullBeat} 
                disabled={loading || userSubscriptionLevel < 2} 
                className={`mt-4 px-6 py-3 rounded-lg font-medium transition-all duration-300 flex items-center justify-center space-x-2 ${
                    loading || userSubscriptionLevel < 2 
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-primary to-primary/80 text-white shadow-lg hover:shadow-xl hover:scale-105'
                }`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
                <span>Make Full Beat</span>
            </button>
        )}
        {audioWaveform && (
            <div className="mt-4">
                <button 
                    onClick={toggleDropDown} 
                    disabled={userSubscriptionLevel < 2}
                    className={`px-6 py-3 rounded-lg font-medium transition-all duration-300 flex items-center justify-center space-x-2 ${
                        userSubscriptionLevel < 2 
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                        : 'bg-gradient-to-r from-secondary/90 to-secondary/70 text-white shadow-lg hover:shadow-xl hover:scale-105'
                    }`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    <span>Edit Beat Sections</span>
                </button>
                
                {showEditDropdown && (
                    <div className="dropdown-menu mt-2 flex flex-col bg-white/90 backdrop-blur-md rounded-lg shadow-xl border border-gray-200 p-2 z-10">
                        <button 
                            onClick={() => handleEditModeChange('swap')} 
                            disabled={userSubscriptionLevel < 2}
                            className={`px-4 py-2 m-1 rounded-md transition-colors duration-200 flex items-center ${
                                userSubscriptionLevel < 2 
                                ? 'text-gray-400 cursor-not-allowed' 
                                : 'text-gray-700 hover:bg-primary hover:text-white'
                            }`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                            </svg>
                            Change Drums
                        </button>
                        <button 
                            onClick={() => handleEditModeChange('remove')} 
                            disabled={userSubscriptionLevel < 2}
                            className={`px-4 py-2 m-1 rounded-md transition-colors duration-200 flex items-center ${
                                userSubscriptionLevel < 2 
                                ? 'text-gray-400 cursor-not-allowed' 
                                : 'text-gray-700 hover:bg-primary hover:text-white'
                            }`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            Remove Drums
                        </button>
                        <button 
                            onClick={() => handleEditModeChange('global')} 
                            disabled={userSubscriptionLevel < 2}
                            className={`px-4 py-2 m-1 rounded-md transition-colors duration-200 flex items-center ${
                                userSubscriptionLevel < 2 
                                ? 'text-gray-400 cursor-not-allowed' 
                                : 'text-gray-700 hover:bg-primary hover:text-white'
                            }`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
                            </svg>
                            Set Global Drums
                        </button>
                    </div>
                )}
            </div>
        )}
            {showBeatEditor && (
                <BeatSectionEditor
                onClose={closeBeatEditor}
                onApplyChanges={handleEditBeat}
                beatStructure={beatStructure}
                editMode={editMode}
                setLoadingState={setLoadingState}
                loading={loading}
                onApplyStart={() => setShowEditDropdown(false)}
                />
            )}
            
     </main>
     
                );
            }
    
export default App;
