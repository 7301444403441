import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/SubscriptionFeatures.css';

const subscriptionOptions = [
    {
        type: 'Free Access',
        price: 'Free',
        features: [
            'Get 25 tokens a month!',
            'Able to test features with limited access',
            'Able to track your generated music',
            'Standard Support Access'
        ],
        buttonText: 'Subscribe',
        color: 'bg-base-100',
        borderColor: 'border-gray-200',
        recommended: false
    },
    {
        type: 'Early Access Premium',
        price: '$7.50',
        period: 'per month',
        features: [
            'Get 100 tokens a month!',
            'Signup Ends on March 1st',
            'Early adopter discount! Lasting 1 whole year. Saving you 62.5% off!',
            'Access to all premium features for a year!',
        ],
        buttonText: 'Subscribe',
        color: 'bg-gradient-to-br from-primary/10 to-primary/5',
        borderColor: 'border-primary/30',
        recommended: true,
        badge: 'BEST VALUE'
    },
    {
        type: 'Premium',
        price: '$20.00',
        period: 'per month',
        features: [
            'Get 100 tokens a month!',
            'Edit generated beats',
            'Generate full beats',
            'Ability to purchase beats',
            'Ability to upload stems to earn money',
            'Priotity support',
            'Access to all new and upcoming features',
        ],
        buttonText: 'Subscribe',
        color: 'bg-base-100',
        borderColor: 'border-gray-200',
        recommended: false
    },
    {
        type: 'Enterprise',
        price: '$500.00',
        period: 'per month',
        features: [
            'Get 1,000 tokens a month!',
            'Instant support access',
            'Required for Record Labels, Publishers, and Businesses',
            'Access to all new and upcoming features',
            'First hand knowledge of new features and ability to provide feedback',
            'Ability to request features',
            'Copyright & publishing auditing support'
        ],
        buttonText: 'Subscribe',
        color: 'bg-gradient-to-br from-secondary/10 to-secondary/5',
        borderColor: 'border-secondary/30',
        recommended: false,
        badge: 'BUSINESS'
    }
];

const SubscriptionFeature = () => {
    const navigate = useNavigate();
    const [hoveredCard, setHoveredCard] = useState(null);

    // Function to handle button click
    const navigateToSubscriptionOptions = () => {
        navigate('/subscriptions');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-base-200 to-base-100 py-16 px-4 sm:px-6 lg:px-8">
            {/* Hero Section */}
            <div className="max-w-7xl mx-auto text-center mb-16">
                <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                    Choose Your Creative Journey
                </h1>
                <p className="text-lg md:text-xl max-w-3xl mx-auto text-base-content/80">
                    Unlock the full potential of AI-powered music production with a plan that fits your creative needs
                </p>
            </div>

            {/* Pricing Toggle - Future Enhancement */}
            {/* <div className="flex justify-center mb-12">
                <div className="flex items-center space-x-3 bg-base-300 p-1 rounded-lg">
                    <button className="px-4 py-2 rounded-md bg-primary text-white font-medium">Monthly</button>
                    <button className="px-4 py-2 rounded-md text-base-content/70 font-medium">Annual</button>
                </div>
                <div className="ml-4 badge badge-secondary">Save 20%</div>
            </div> */}

            {/* Pricing Cards */}
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
                {subscriptionOptions.map((option, index) => (
                    <div 
                        key={option.type}
                        className={`card ${option.color} border-2 ${option.borderColor} shadow-xl overflow-hidden transition-all duration-300 ${hoveredCard === index ? 'scale-105 shadow-2xl z-10' : ''} ${option.recommended ? 'md:-mt-4 md:mb-4' : ''}`}
                        onMouseEnter={() => setHoveredCard(index)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        {option.badge && (
                            <div className="absolute top-0 right-0">
                                <div className={`badge ${option.recommended ? 'badge-primary' : 'badge-secondary'} m-2`}>
                                    {option.badge}
                                </div>
                            </div>
                        )}
                        
                        <div className="card-body">
                            <h2 className="card-title text-xl font-bold">{option.type}</h2>
                            <div className="flex items-end mt-2 mb-6">
                                <span className="text-4xl font-extrabold">{option.price}</span>
                                {option.period && <span className="text-base-content/70 ml-1 mb-1">{option.period}</span>}
                            </div>
                            
                            <div className="divider my-1"></div>
                            
                            <ul className="space-y-3 mb-6">
                                {option.features.map((feature, idx) => (
                                    <li key={idx} className="flex items-start">
                                        <svg className="h-5 w-5 text-success flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="text-sm">{feature}</span>
                                    </li>
                            ))}
                        </ul>
                            
                            <div className="card-actions mt-auto">
                                <button 
                                    className={`btn w-full ${option.recommended ? 'btn-primary' : 'btn-outline'}`}
                                    onClick={navigateToSubscriptionOptions}
                                >
                                    {option.buttonText}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Features Comparison */}
            <div className="max-w-5xl mx-auto mb-16 bg-base-100 rounded-box shadow-lg overflow-hidden">
                <div className="p-6 text-center">
                    <h2 className="text-2xl font-bold mb-2">Compare All Features</h2>
                    <p className="text-base-content/70">See which plan is right for you</p>
                </div>
                
                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className="bg-base-200">Feature</th>
                                <th className="text-center bg-base-200">Free</th>
                                <th className="text-center bg-primary/10">Early Access</th>
                                <th className="text-center bg-base-200">Premium</th>
                                <th className="text-center bg-secondary/10">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Monthly Tokens</td>
                                <td className="text-center">25</td>
                                <td className="text-center">100</td>
                                <td className="text-center">100</td>
                                <td className="text-center">1,000</td>
                            </tr>
                            <tr>
                                <td>Beat Generation</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                            </tr>
                            <tr>
                                <td>Edit Generated Beats</td>
                                <td className="text-center">-</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                            </tr>
                            <tr>
                                <td>Generate Full Beats</td>
                                <td className="text-center">-</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                            </tr>
                            <tr>
                                <td>Sell Beats</td>
                                <td className="text-center">-</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                            </tr>
                            <tr>
                                <td>Priority Support</td>
                                <td className="text-center">-</td>
                                <td className="text-center">-</td>
                                <td className="text-center">✓</td>
                                <td className="text-center">✓</td>
                            </tr>
                            <tr>
                                <td>Feature Requests</td>
                                <td className="text-center">-</td>
                                <td className="text-center">-</td>
                                <td className="text-center">-</td>
                                <td className="text-center">✓</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="max-w-3xl mx-auto mb-16">
                <h2 className="text-2xl font-bold text-center mb-8">Frequently Asked Questions</h2>
                
                <div className="join join-vertical w-full">
                    <div className="collapse collapse-arrow join-item border border-base-300">
                        <input type="radio" name="faq-accordion" defaultChecked /> 
                        <div className="collapse-title text-lg font-medium">
                            What are tokens and how do they work?
                        </div>
                        <div className="collapse-content"> 
                            <p>Tokens are the currency used for generating music with our AI. Each generation costs a certain number of tokens depending on the complexity and length of the beat. Your subscription includes a monthly allocation of tokens that refreshes each billing cycle.</p>
                        </div>
                    </div>
                    
                    <div className="collapse collapse-arrow join-item border border-base-300">
                        <input type="radio" name="faq-accordion" /> 
                        <div className="collapse-title text-lg font-medium">
                            Can I upgrade or downgrade my plan?
                        </div>
                        <div className="collapse-content"> 
                            <p>Yes, you can upgrade or downgrade your subscription at any time. Changes will take effect at the start of your next billing cycle. Early Access pricing is locked in for a full year from your signup date.</p>
                        </div>
                    </div>
                    
                    <div className="collapse collapse-arrow join-item border border-base-300">
                        <input type="radio" name="faq-accordion" /> 
                        <div className="collapse-title text-lg font-medium">
                            Who owns the music I create?
                        </div>
                        <div className="collapse-content"> 
                            <p className="mb-2">By default, all AI-generated music is licensed for <span className="font-semibold">personal listening only</span>. This means you can enjoy your creations but cannot commercially distribute them without purchasing rights.</p>
                            <p className="mb-2">When you purchase rights to a generation, the specific license terms are determined by the contributors whose samples were used in creating that beat. Each contributor sets their own terms, and we calculate a fair license based on all contributions.</p>
                            <p>This ensures that both creators and contributors benefit from the platform while maintaining clear ownership rights.</p>
                        </div>
                    </div>
                    
                    <div className="collapse collapse-arrow join-item border border-base-300">
                        <input type="radio" name="faq-accordion" /> 
                        <div className="collapse-title text-lg font-medium">
                            How long will the Early Access pricing be available?
                        </div>
                        <div className="collapse-content"> 
                            <p>Early Access pricing is available until June 1st. After that, new subscribers will pay the standard Premium rate. If you subscribe before the deadline, you'll enjoy the discounted rate for a full year.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="max-w-4xl mx-auto text-center bg-gradient-to-r from-primary/20 to-secondary/20 rounded-box p-8 shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Music Production?</h2>
                <p className="mb-6">Join our community of creators and start making professional-quality beats today.</p>
                <button 
                    className="btn btn-primary btn-lg"
                    onClick={navigateToSubscriptionOptions}
                >
                    Get Started Now
                </button>
                <p className="mt-4 text-sm text-base-content/70">No credit card required for free tier. Cancel anytime.</p>
            </div>
        </div>
    );
};

export default SubscriptionFeature;
