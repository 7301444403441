import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './BannerPage.css';

const BannerPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [hasStripeAccount, setHasStripeAccount] = useState(false);

  useEffect(() => {
    const isMounted = { current: true };
    
    const checkStripeAccount = async () => {
      // Skip if not authenticated
      if (!isAuthenticated) return;
      
      // Check if we've already checked the Stripe account in this session
      const hasCheckedStripe = localStorage.getItem('hasCheckedStripe');
      if (hasCheckedStripe === 'true') return;
      
      try {
        const idToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check_stripe_account`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        
        const data = await response.json();
        if (isMounted.current && data.has_account) {
          localStorage.setItem('hasCheckedStripe', 'true');
          setHasStripeAccount(true);
        }
      } catch (error) {
        console.error('Error checking Stripe account:', error);
      }
    };

    if (isAuthenticated) {
      checkStripeAccount();
    } else {
      // Clear the flag when user is not authenticated
      localStorage.removeItem('hasCheckedStripe');
    }
    
    // Cleanup function
    return () => {
      isMounted.current = false;
    };
  }, [isAuthenticated]); // Remove getAccessTokenSilently from dependencies

  const handleProducerClick = () => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      if (hasStripeAccount) {
        navigate('/contributor-setup');
      } else {
        navigate('/Producer-Signup');
      }
    }
  };

  return (
    <>
      <div className="banner-text">
        <div>
          <h1>Contribute To Our Dataset</h1>
          <p>Submit your copyrighted material to our database and earn from usage.</p>
        </div>
      </div>
      <div className="banner-page">
        <div className="banner producer-banner" onClick={handleProducerClick}>
          <h2>Producer</h2>
          <p>Submit drum & sample stems</p>
          <button>Sign Up</button>
        </div>
        <div className="banner songwriter-banner">
          <h2>Songwriter</h2>
          <p>Submit song lyrics</p>
          <p>Coming Soon</p>
        </div>
        <div className="banner vocal-artist-banner">
          <h2>Vocal Artist</h2>
          <p>Submit vocal stems</p>
          <p>Coming Soon</p>
        </div>
      </div>
    </>
  );
};

export default BannerPage;
