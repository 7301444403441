import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Notifications from './Notifications';
import { useAuth0 } from '@auth0/auth0-react';
import RightsPopup from './RightsPopup';
import fetchLeaseOptions from './fetchLeaseOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo, faDownload, faVolumeUp, faGem, faMusic } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { loadStripe } from '@stripe/stripe-js';
import { displayError, displaySuccess } from './utils';

function WaveformPlayer({ beat_id, audio_url, Beatkey, mode, bpm, beatTitle, beatStructureLabels }) {
    const [errorMessages, setErrorMessages] = useState('');

    const { getIdTokenClaims } = useAuth0();
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioReady, setAudioReady] = useState(false);
    const [volume, setVolume] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [userInfo, setUserInfo] = useState({
        fname: '',
        lname: '',
        country: '',
    });
    const [isInfoComplete, setIsInfoComplete] = useState(false);
    const [showRightsPopup, setShowRightsPopup] = useState(false);
    const [leaseOptions, setLeaseOptions] = useState([]);

    const downloadBeat = () => {
        if (!audio_url) {
            alert('No beat available for download.');
            return;
        }
    
        fetch(audio_url)
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, beatTitle);
            })
            .catch(e => {
                console.error("Download failed", e);
                alert("Failed to download the beat.");
            });
    };

    useEffect(() => {
        // Fetch user info from the backend to check if it's complete
        const fetchUserInfo = async () => {
            try {
                const idTokenClaims = await getIdTokenClaims();
                const idToken = idTokenClaims.__raw;
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-info`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                setUserInfo({
                    fname: data.first_name,
                    lname: data.last_name,
                    country: data.country,
                });
                setIsInfoComplete(data.first_name && data.last_name && data.country);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, []);

    useEffect(() => {
        const loadLeaseOptions = async () => {
            const options = await fetchLeaseOptions(beat_id);
            setLeaseOptions(options);
        };
        loadLeaseOptions();
    }, [beat_id]);

    useEffect(() => {
        if (wavesurfer.current) {
            wavesurfer.current.setVolume(volume);
        }
    }, [volume]);

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
        if (wavesurfer.current) {
            wavesurfer.current.setVolume(newVolume);
        }
    };

    useEffect(() => {
        wavesurfer.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D9DCFF',
            progressColor: '#4F46E5', // Indigo-600
            cursorColor: '#4F46E5',
            barWidth: 2,
            barGap: 2,
            barRadius: 3,
            height: 80,
            responsive: true,
            normalize: true,
            partialRender: true,
            pixelRatio: 1
        });

        wavesurfer.current.load(audio_url);

        // Event listeners
        wavesurfer.current.on('play', () => setIsPlaying(true));
        wavesurfer.current.on('pause', () => setIsPlaying(false));
        wavesurfer.current.on('stop', () => setIsPlaying(false));
        wavesurfer.current.on('ready', () => {
            setAudioReady(true);
            setTotalDuration(wavesurfer.current.getDuration());
        });
        wavesurfer.current.on('audioprocess', (time) => {
            setCurrentTime(time);
        });

        return () => {
            wavesurfer.current.destroy();
        };
    }, [audio_url]);

    const handlePlayPause = () => {
        if (!audioReady) {
            alert('Beat is not ready yet. Wait 1 moment.');
            return;
        }
        if (isPlaying) {
            wavesurfer.current.pause();
        } else {
            wavesurfer.current.play().catch(error => {
                alert("Error playing audio. Please try again.");
                console.error("Error playing audio:", error);
            });
        }
    };
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleRestart = () => {
        if (wavesurfer.current) {
            wavesurfer.current.seekTo(0);
            if (isPlaying) {
                wavesurfer.current.play();
            }
        }
    };

    const handlePurchase = async (priceId) => {
        try {
            const idTokenClaims = await getIdTokenClaims();
            const idToken = idTokenClaims.__raw;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    beat_id: beat_id,
                    lease_option_id: priceId,
                    first_name: userInfo.fname,
                    last_name: userInfo.lname,
                    country: userInfo.country,
                }),
            });
            const session = await response.json();
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleGetLicensingInfo = () => {
        if (isInfoComplete) {
            setShowRightsPopup(true);
        } else {
            displayError(setErrorMessages)('Please complete your profile information to proceed.');
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto bg-white dark:bg-gray-900 rounded-xl shadow-lg overflow-hidden transition-all duration-300 transform hover:shadow-xl">
            {/* Beat Header with Title */}
            <div className="relative bg-gradient-to-r from-indigo-600 to-purple-600 px-6 py-4">
                <div className="absolute inset-0 bg-black opacity-20"></div>
                <div className="relative flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <div className="bg-white bg-opacity-20 rounded-full p-2">
                            <FontAwesomeIcon icon={faMusic} className="text-white text-lg" />
                        </div>
                        <h2 className="text-xl md:text-2xl font-bold text-white truncate">{beatTitle}</h2>
                    </div>
                    <div className="text-white text-sm font-medium bg-black bg-opacity-30 px-3 py-1 rounded-full">
                        {formatTime(currentTime)} / {formatTime(totalDuration)}
                    </div>
                </div>
            </div>
            
            {/* Waveform Container */}
            <div className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                <div className="relative" ref={waveformRef}></div>
            </div>
            
            {/* Controls Section */}
            <div className="px-6 py-3 bg-white dark:bg-gray-900 border-t border-gray-100 dark:border-gray-800">
                <div className="flex items-center justify-between">
                    {/* Play Controls */}
                    <div className="flex items-center space-x-3">
                        <button 
                            onClick={handleRestart}
                            disabled={!audioReady}
                            className="w-8 h-8 flex items-center justify-center rounded-full text-indigo-600 dark:text-indigo-400 hover:bg-indigo-100 dark:hover:bg-indigo-900 transition-colors duration-200 disabled:opacity-50"
                        >
                            <FontAwesomeIcon icon={faRedo} />
                        </button>
                        
                        <button 
                            onClick={handlePlayPause}
                            disabled={!audioReady}
                            className={`w-12 h-12 flex items-center justify-center rounded-full transition-all duration-300 ${
                                isPlaying 
                                ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400' 
                                : 'bg-indigo-600 text-white shadow-md hover:bg-indigo-700'
                            }`}
                        >
                            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} className="text-lg" />
                        </button>
                    </div>
                    
                    {/* Volume Control */}
                    <div className="flex items-center space-x-3 flex-1 max-w-xs ml-4">
                        <FontAwesomeIcon icon={faVolumeUp} className="text-gray-500 dark:text-gray-400" />
                        <div className="w-full">
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={handleVolumeChange}
                                className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer accent-indigo-600"
                            />
                        </div>
                        <span className="text-xs font-medium text-gray-500 dark:text-gray-400 w-10 text-right">
                            {Math.round(volume * 100)}%
                        </span>
                    </div>
                </div>
            </div>
            
            {/* Beat Info Section */}
            <div className="grid grid-cols-3 gap-2 px-6 py-3 bg-gray-50 dark:bg-gray-800 border-t border-gray-100 dark:border-gray-800">
                <div className="flex flex-col items-center justify-center p-2 bg-white dark:bg-gray-900 rounded-lg shadow-sm">
                    <span className="text-xs text-gray-500 dark:text-gray-400 uppercase tracking-wide">Key</span>
                    <span className="text-lg font-semibold text-gray-800 dark:text-white">{Beatkey}</span>
                </div>
                <div className="flex flex-col items-center justify-center p-2 bg-white dark:bg-gray-900 rounded-lg shadow-sm">
                    <span className="text-xs text-gray-500 dark:text-gray-400 uppercase tracking-wide">Mode</span>
                    <span className="text-lg font-semibold text-gray-800 dark:text-white">{mode}</span>
                </div>
                <div className="flex flex-col items-center justify-center p-2 bg-white dark:bg-gray-900 rounded-lg shadow-sm">
                    <span className="text-xs text-gray-500 dark:text-gray-400 uppercase tracking-wide">BPM</span>
                    <span className="text-lg font-semibold text-gray-800 dark:text-white">{bpm}</span>
                </div>
            </div>
            
            {/* Beat Structure */}
            <div className="px-6 py-3 bg-white dark:bg-gray-900 border-t border-gray-100 dark:border-gray-800">
                <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-1">Structure</span>
                    <div className="bg-gray-50 dark:bg-gray-800 p-2 rounded-lg">
                        <span className="text-sm font-medium text-gray-800 dark:text-white">{beatStructureLabels}</span>
                    </div>
                </div>
            </div>
            
            {/* Action Buttons */}
            <div className="px-6 py-4 bg-white dark:bg-gray-900 border-t border-gray-100 dark:border-gray-800 flex flex-wrap gap-3 justify-center">
                {audio_url && (
                    <button 
                        onClick={downloadBeat}
                        className="flex items-center justify-center px-4 py-2 bg-gray-100 dark:bg-gray-800 text-indigo-600 dark:text-indigo-400 rounded-lg font-medium transition-all duration-200 hover:bg-indigo-600 hover:text-white"
                    >
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                        Download
                    </button>
                )}
                
                <button 
                    onClick={handleGetLicensingInfo}
                    className="flex items-center justify-center px-4 py-2 bg-indigo-600 text-white rounded-lg font-medium transition-all duration-200 hover:bg-indigo-700 shadow-md hover:shadow-lg"
                >
                    <FontAwesomeIcon icon={faGem} className="mr-2" />
                    Get License
                </button>
            </div>
            
            <Notifications
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                displayError={displayError}
            />
            
            <RightsPopup
                isOpen={showRightsPopup}
                onClose={() => setShowRightsPopup(false)}
                leaseOptions={leaseOptions}
                onPurchase={handlePurchase}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getIdTokenClaims={getIdTokenClaims}
            />
        </div>
    );
}

export default WaveformPlayer;