// TermsConditions.js
import React, { useState } from 'react';

const TermsConditions = () => {
    const [activeTab, setActiveTab] = useState('terms');

    return (
        <div className="min-h-screen bg-gradient-to-b from-base-200 to-base-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <div className="text-center mb-10">
                    <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                        Terms & Privacy
                    </h1>
                    <p className="text-base-content/70 max-w-2xl mx-auto">
                        Please review our terms and privacy policy to understand how we operate and protect your data
                    </p>
                </div>

                {/* Tab Navigation */}
                <div className="tabs tabs-boxed bg-base-300 p-1 mb-8 flex justify-center">
                    <button 
                        className={`tab ${activeTab === 'terms' ? 'tab-active bg-primary text-white' : ''}`}
                        onClick={() => setActiveTab('terms')}
                    >
                        Terms & Conditions
                    </button>
                    <button 
                        className={`tab ${activeTab === 'privacy' ? 'tab-active bg-primary text-white' : ''}`}
                        onClick={() => setActiveTab('privacy')}
                    >
                        Privacy Policy
                    </button>
                </div>

                {/* Terms Content */}
                {activeTab === 'terms' && (
                    <div className="bg-base-100 rounded-lg shadow-xl p-6 mb-8 border border-base-300 animate-fadeIn">
                        <h2 className="text-2xl font-semibold mb-6 text-primary">General Usage Terms</h2>
                        <div className="prose max-w-none">
                <p className="mb-4">Welcome to YSM.AI! We are a music generation platform that utilizes copyrighted material from producers, songwriters, and artists to allow users to ethically license and generate music. Please read our Terms and Conditions carefully before using our service.</p>
                            
                            <div className="divider my-6"></div>
                            
                            <h3 className="text-xl font-semibold mb-3">Key Terms</h3>
                            <ul className="space-y-3">
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>All users must adhere to the terms and permissions associated with their account. Any manipulation of privileges and permissions is strictly prohibited.</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>Users are granted the right to generate music using our platform within the scope of their subscription and the rights granted therein.</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>Resale or redistribution of music generated on YSM.AI without proper licensing is not allowed.</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>YSM.AI reserves the right to modify, suspend, or discontinue its services at any time without notice.</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>Users must respect copyright laws and ensure they have the necessary rights for any materials they upload or use within the platform.</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="h-5 w-5 text-primary flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                    </svg>
                                    <span>Violation of these terms may result in suspension or termination of your account.</span>
                                </li>
                </ul>
                            
                            <div className="alert alert-info mt-8">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <span>By using YSM.AI, you acknowledge that you have read and agree to these terms.</span>
                            </div>
                        </div>
            </div>
                )}

                {/* Privacy Policy Content */}
                {activeTab === 'privacy' && (
                    <div className="bg-base-100 rounded-lg shadow-xl p-6 mb-8 border border-base-300 animate-fadeIn">
                        <h2 className="text-2xl font-semibold mb-6 text-primary">YSM.AI Privacy Policy</h2>
                        <div className="prose max-w-none">
                <p className="mb-4">Welcome to YSM.AI, a music generation platform operated by UrStudio Corp. We are dedicated to respecting your privacy and safeguarding your personal data. This document outlines our practices regarding data handling, your rights, and our responsibilities.</p>
                
                            <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4">
                                <input type="checkbox" defaultChecked /> 
                                <div className="collapse-title text-lg font-medium">
                                    1. Data Collection and Processing
                                </div>
                                <div className="collapse-content"> 
                                    <ul className="space-y-3">
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div>
                                                <span className="font-semibold">Account Information:</span> We collect information through OAuth by Google and other services, including your name, email address, nickname, and username for account creation and management.
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div>
                                                <span className="font-semibold">Interaction Data:</span> Data related to user interactions, such as beat generation searches, prompting, and engagement with generated music, are collected to enhance user experience and service functionality. These searches and prompts users submit may be used to train and improve our models. By using our services, you agree to allow us to collect and use this data.
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div>
                                                <span className="font-semibold">Analytics:</span> Generalized analytics are used to measure site performance, track user locations, and analyze retention rates, helping us understand how our services are used and how they can be improved.
                                            </div>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div>
                                                <span className="font-semibold">Uploads and Content:</span> We process data related to any materials you upload or use within the platform, ensuring compliance with copyright laws and the terms of your subscription. Uploads are paired with files and metadata information in which you supply, all of which will be utilized to help improve our models and training systems. By uploading content and providing metadata text information, you agree to allow us to collect and use this data to ensure compliance with copyright laws and the terms of your subscription.
                                            </div>
                                        </li>
                </ul>
                                </div>
                            </div>
                            
                            <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4">
                                <input type="checkbox" /> 
                                <div className="collapse-title text-lg font-medium">
                                    2. Data Usage
                                </div>
                                <div className="collapse-content"> 
                                    <ul className="space-y-3">
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Data collected is primarily used to provide and improve our services, facilitate account management, and enhance user interaction with our platform.</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>We may use certain user data for targeted advertising and marketing on platforms like Meta, Google, and others, ensuring content relevance and effective promotion.</span>
                                        </li>
                </ul>
                                </div>
                            </div>
                            
                            <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4">
                                <input type="checkbox" /> 
                                <div className="collapse-title text-lg font-medium">
                                    3. Data Sharing and Disclosure
                                </div>
                                <div className="collapse-content"> 
                                    <ul className="space-y-3">
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>We do not share personal data with third-party partners or advertisers, except for necessary disclosures to Streaming Platforms or Copyright and Publishing holders.</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Any data sharing is conducted in strict adherence to legal requirements and with the utmost respect for user privacy.</span>
                                        </li>
                </ul>
                                </div>
                            </div>
                            
                            <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4">
                                <input type="checkbox" /> 
                                <div className="collapse-title text-lg font-medium">
                                    4. User Rights and Responsibilities
                                </div>
                                <div className="collapse-content"> 
                                    <ul className="space-y-3">
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Users have the right to terminate their account at any time. However, content generated on YSM.AI will be retained for legal and operational reasons, including copyright and publishing compliance.</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="h-5 w-5 text-info flex-shrink-0 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Users are expected to respect copyright laws and adhere to the terms and permissions associated with their account and subscription.</span>
                                        </li>
                </ul>
                                </div>
                            </div>
                            
                            <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-4">
                                <input type="checkbox" /> 
                                <div className="collapse-title text-lg font-medium">
                                    5-8. Additional Policies
                                </div>
                                <div className="collapse-content"> 
                                    <h4 className="font-semibold mt-2">5. International Data Transfer</h4>
                                    <p className="mb-3">As an international platform, data may be transferred across borders. We ensure such transfers comply with relevant data protection laws and maintain data security.</p>
                                    
                                    <h4 className="font-semibold mt-4">6. Security Measures</h4>
                                    <p className="mb-3">All interactions with YSM.AI are encrypted, and data is securely stored on our servers. We do not store sensitive information like passwords or payment details.</p>
                                    <p className="mb-3">Security measures are continually reviewed and updated to protect against unauthorized access, alteration, or destruction of your personal information.</p>
                                    
                                    <h4 className="font-semibold mt-4">7. Policy Updates</h4>
                                    <p className="mb-3">We reserve the right to update this privacy policy. Changes will be communicated to users, maintaining transparency and compliance with legal standards.</p>
                                    
                                    <h4 className="font-semibold mt-4">8. Contact Information</h4>
                                    <p className="mb-3">For privacy-related inquiries, users can contact us via our contact form or email. We are committed to addressing your concerns promptly and effectively.</p>
                                </div>
                            </div>
                            
                            <div className="alert alert-success mt-8">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                <div>
                                    <h3 className="font-bold">Thank you for choosing YSM.AI!</h3>
                                    <div className="text-sm">By using our services, you agree to abide by these privacy terms. We value your trust and are committed to protecting your personal data.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TermsConditions;
